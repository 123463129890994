.tax-documents-container {
    font-family: sans-serif;
  }
  
  .view-checklist-btn {
    background-color: #3182ce;
    color: white;
    font-weight: bold;
   
    padding: 0.5rem 1rem;
    border-radius: 0.25rem;
    transition: all 0.3s ease-in-out;
    transform: scale(1);
  }
  
  .view-checklist-btn:hover {
    background-color: #2c5282;
    transform: scale(1.05);
  }
  
  .view-checklist-btn:focus {
    outline: none;
    box-shadow: 0 0 0 3px rgba(49, 130, 206, 0.5);
  }
  
  .modal-content {
    border-radius: 0.5rem;
  }
  
  .modal-header {
    border-bottom: none;
    background-color: #f7fafc;
    border-top-left-radius: 0.5rem;
    border-top-right-radius: 0.5rem;
  }
  
  .modal-title {
    font-size: 1.5rem;
    font-weight: bold;
    color: #2d3748;
  }
  
  .close-btn {
    color: #718096;
    transition: color 0.2s ease-in-out;
  }
  
  .close-btn:hover {
    color: #2d3748;
  }
  
  .modal-body {
    padding-top: 0;
  }
  
  .modal-description {
    color: #718096;
    margin-bottom: 1rem;
  }
  
  .document-category {
    margin-bottom: 1.5rem;
  }
  
  .document-category h5 {
    font-size: 1.125rem;
    font-weight: 600;
    color: #2d3748;
    margin-bottom: 0.5rem;
  }
  
  .list-group {
    border-radius: 0.375rem;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  }
  
  .list-group-item {
    padding: 0.75rem 1rem;
    border-bottom: 1px solid #e2e8f0;
    transition: background-color 0.15s ease-in-out;
  }
  
  .list-group-item:last-child {
    border-bottom: none;
  }
  
  .list-group-item:hover {
    background-color: #f7fafc;
  }
  
  .modal-footer {
    border-top: none;
    background-color: #f7fafc;
    border-bottom-left-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem;
  }
  
  .close-modal-btn {
    background-color: #e2e8f0;
    color: #2d3748;
    font-weight: bold;
    padding: 0.5rem 1rem;
    border-radius: 0.25rem;
    transition: all 0.3s ease-in-out;
  }
  
  .close-modal-btn:hover {
    background-color: #cbd5e0;
  }
  
  .close-modal-btn:focus {
    outline: none;
    box-shadow: 0 0 0 3px rgba(203, 213, 224, 0.5);
  }