.navContainer {
    display: flex;
    justify-content: flex-end; /* Align horizontally at the start */
    align-items: flex-end; /* Align vertically at the top */
    width: 100%;

  }

  
  .navContainer ul {
    display:flex;
    list-style: none; /* Remove bullet points */
    padding: 0; /* Remove default padding */
    margin: 0; /* Remove default margin */
    gap: 20px; /* Space between list items */
  }
  
  .navContainer li {
    font-size: 16px; /* Font size for list items */
    color: #333; /* Text color */
  }
  
  .navContainer li:hover {
    cursor: pointer; /* Pointer cursor on hover */
  }
  .navIconContainer {

   
    cursor: pointer;
  }
  .navIconContainer img{
    position:fixed;
    top:0;
    right:0;
    margin-top:10px;
  }
  

  .vertNav{
    display:flex;
    flex-direction: column;
    text-align: center;


    
    
  }
  .navItems {
    list-style-type: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: row;
    align-items: center;

    
  }
  
  .navItems li {
    text-align: center;
  }
  
  .navItems a {
    font-family: 'Montserrat', sans-serif;  /* Modern, sleek font */
    font-size: 18px;
    font-weight: 300;
    color: #333;
    text-decoration: none;
    letter-spacing: 1px;
    padding: 10px 20px;
    display: block;
    position: relative;
    transition: all 0.3s ease;
  }
  .vertNav a {
    font-family: 'Montserrat', sans-serif;  /* Modern, sleek font */
    font-size: 18px;
    font-weight: 300;
    color: #333;
    text-decoration: none;
    letter-spacing: 1px;
    padding: 10px 20px;
    display: block;
    position: relative;
    transition: all 0.3s ease;

  }
  
  .navItems a:before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 1px;
    background-color: #333;
    transform: scaleX(0);
    transform-origin: right;
    transition: transform 0.3s ease;
  }
  .vertNav a:before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 1px;
    background-color: #333;
    transform: scaleX(0);
    transform-origin: right;
    transition: transform 0.3s ease;
  }
  
  .navItems a:hover {
    color: #000;
    font-weight: 400;
  }
  
  .navItems a:hover:before {
    transform: scaleX(1);
    transform-origin: left;
  }
  