/* MainContent.css */

.contentContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  width: 100%;
  text-align: center;
}
.textAnimateDiv{
  padding-top:10vh;
}

.contentMain {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 90%;
  max-width: 1200px;
}

.appointmentDiv {
  margin-top: 5vh;
}

button {
  border-radius: 8px;
  font-size: clamp(1rem, 2.5vw, 1.5rem);
  padding: 0.5em 1em;
}

.documentDiv {
  padding: 5vh 0;
  width: 100%;
}

.mapDiv {
  width: 100%;
  margin-top: 3vh;
  border-radius: 25px;
}

@media screen and (max-width: 768px) {
  .contentMain {
    width: 95%;
  }
}