.accordion{
    width:100%;
    border:none !important;
}
.accordion-item{
    width:100%;
    border:none !important;
}
.accordion-header{
    border-bottom:2px black;

}



.accordion-button:focus {
    box-shadow: none;
   
  }
  .accordion-button {
    background-color: #EFF0E8 !important;
    color:black !important;
    align-items: center !important;
    justify-content: center !important;
    box-shadow:none !important;
    

}
.accordion-item:first-of-type>.accordion-header .accordion-button {
  border-top-left-radius: 25px !important;
  border-top-right-radius: 25px !important;
  text-align: center !important;

}
.accordion-item:last-of-type>.accordion-header .accordion-button.collapsed {

  border-bottom-left-radius:25px !important;


  border-bottom-right-radius:25px !important;
}




  
  .accordion-button .collapsed {
    background-color: transparent; /* Or any other color you prefer */
    color: inherit;
    border-bottom: 2px solid black !important;
  }
  

  

  .accordionDiv{
    width:50%;
    
  }
.accordionMain{
    width:100%;
}