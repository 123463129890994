button {
	height: 100%;
    width:100%;
	padding: 0;
	display: grid;
	border-radius: 8px;
	border: 2px solid black;
	letter-spacing: 0.25px;
	cursor: pointer;
	position: relative;
	background: black;
	color: white;
	font-weight: 80;
	text-align: center;
	scale: 2;
  outline-color: hsl(0, 100%, 46%);
  outline-offset: 0.25rem;
}

.appointmentCDiv{
    padding: 3vh 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}
button:is(:hover, :focus-visible) {
	--active: 1;
}

button:active {
  --pressed: 1;
}

button > span:nth-of-type(1) {
	height: 100%;
	width: 100%;
	border-radius: 8px;
	position: absolute;
	inset: 0;
  scale: calc(1 - (var(--pressed, 0) * 0.05));
  transition: scale 0.1s;
}

button:is(:hover, :focus-visible) .buttonContainer {
	width: 100%;

}

.buttonContainer {
	--mask-image: url(https://assets.codepen.io/605876/chev-mask_1.png);
	--spread: 24px;
	--size: 28px;
	width: 20%;
	height: 100%;
	text-align: center;

	background: hsl(0, 100%, 43%);
	position: absolute;
	left: 0;
	transition: width 0.25s;
	border-radius: 6px;
	box-shadow: 0 10px 10px -5px hsl(0 0% 0% / 0.5);
	container-type: inline-size;
}

.buttonPrimary {
	content: "";
    border-radius: 8px;
	position: absolute;
	text-align: center;

	inset: 0;
	background: hsl(0 0% 0% / 0.15);
	z-index: 2;
	mask: var(--mask-image) 50% 50% / var(--size) var(--size) no-repeat;
	container-type: inline-size;
}

:where(.buttonPrimary, .buttonComplimentary)::after {
	--distance: calc(100cqi + 100%);
	content: "";
	height: calc(var(--size) * 4);
	aspect-ratio: 1;
	position: absolute;
	left: 0%;
	top: 50%;
	translate: -50% -50%;
	background: radial-gradient(hsl(0 0% 0%), transparent);
	animation: fly-by calc((2 - var(--active, 0)) * 1s) infinite linear;
}
.appointmentButtonMain{
    border-radius:8px;
}

@keyframes fly-by {
	0% { translate: -100% -50%; }
	100% { translate: var(--distance) -50%; }
}

.buttonComplimentary {
	content: "";
	text-align: center;

	position: absolute;
    border-radius: 8px;
	inset: 0;
	opacity: var(--active, 0);
	transition: opacity 0.25s;
	background: hsl(0 0% 0% / 0.15);
	mask:
		var(--mask-image) calc(50% - (var(--spread) * 1)) 50% / var(--size) var(--size) no-repeat,
		var(--mask-image) calc(50% - (var(--spread) * 2)) 50% / var(--size) var(--size) no-repeat,
		var(--mask-image) calc(50% - (var(--spread) * 3)) 50% / var(--size) var(--size) no-repeat,
		var(--mask-image) calc(50% + (var(--spread) * 1)) 50% / var(--size) var(--size) no-repeat,
		var(--mask-image) calc(50% + (var(--spread) * 2)) 50% / var(--size) var(--size) no-repeat,
		var(--mask-image) calc(50% + (var(--spread) * 3)) 50% / var(--size) var(--size) no-repeat;
}

button > span:nth-of-type(2) {
	padding: 0 1.1rem 0 calc(58px + 1.1rem);
	display: grid;
	place-items: center;
    border-radius: 8px;
	height: 100%;
}
@media screen and (max-width: 768px) {
	.appointmentButtonMain{
		height:50% !important;
		width:100%;
	}
	
	
}
/* AppointmentModal.css */

.appointment-modal .modal-body {
	padding: 0; /* Remove padding to allow overlay to cover entire body */
  }
  
  .demo-overlay {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent black */
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 1000; /* Ensure it's above the iframe */
  }
  
  .demo-overlay span {
	color: white;
	font-size: 24px;
	font-weight: bold;
	text-transform: uppercase;
	padding: 10px 20px;
	background-color: rgba(0, 0, 0, 0.7);
	border-radius: 5px;
  }
  
  /* Optional: Add some styling to the modal itself */
  .appointment-modal .modal-content {
	border-radius: 10px;
  }
  
  .appointment-modal .modal-header {
	background-color: #f8f9fa;
	border-bottom: 1px solid #dee2e6;
  }
  
  .appointment-modal .modal-title {
	font-weight: bold;
  }