.parentContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100%;
}
.mainContainer{
  height: 97%;
  width: 97%;
  background: #ffffff;
  border-radius: 25px;

}

.mainDiv {
  display: flex;
  flex-direction: column; /* Stack children vertically */
  justify-content: flex-end;

 
}

.navParent {
  display: flex;
  justify-content: space-between;
  height: auto; /* Adjust height */
  width: 100%;
  position: relative; /* Keep this line */
}

.navDiv {
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

.logoDiv {
  position: absolute;
  top: 0;
  left: 0;
}

.logoImage {
  border-radius: 25px;
  height: 35%;
  width: 35%;
}

.accordion-item {
  width: 100%;
}

.accordionDiv {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 20px;
  width: 100%;
}

.mainContent {
  display:flex;
  height:95vh;
  justify-content: center;
  align-items: center;
}
